import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
const Privacy = () => {
  return (
    <Layout>
      <SEO title={`Privacy Policy `} />
      <div className="main">
        <div className="container">
          <div className="section_posting">
            <div className="mask_logo">
              +Add<strong>Friends</strong>
            </div>
            <div className="box">
              <h1>Privacy Policy</h1>
              <div className="row">
                <p>
                  This privacy policy has been compiled to better serve those
                  who are concerned with how their 'Personally Identifiable
                  Information' (PII) is being used online. PII, as described in
                  US privacy law and information security, is information that
                  can be used on its own or with other information to identify,
                  contact, or locate a single person, or to identify an
                  individual in context. Please read our privacy policy
                  carefully to get a clear understanding of how we collect, use,
                  protect or otherwise handle your Personally Identifiable
                  Information in accordance with our website.
                </p>
                <h3 className="terms">
                  What personal information do we collect from the people that
                  visit our website, email us, or interact with us via
                  third-party social media?
                </h3>
                <div className="termsAnswer">
                  <p>
                    When ordering or registering on our site, as appropriate,
                    you may be asked to enter your name, email address, IP or
                    other details to help you with your experience.
                  </p>
                </div>
                <h3 className="terms">When do we collect information?</h3>
                <div className="termsAnswer">
                  <p>
                    We collect information from you when you register on our
                    site, subscribe to a newsletter, fill out a form, navigate
                    our website, email us, or enter and other information on our
                    site.
                  </p>
                </div>

                <h3 className="terms">How do we use your information?</h3>
                <div className="termsAnswer">
                  <p>
                    We may use the information we collect from you when you
                    register, make a purchase, sign up for our newsletter,
                    respond to a survey or marketing communication, surf the
                    website, or use certain other site features in the following
                    ways:
                  </p>
                  <ul>
                    <li>
                      -To personalize your experience and to allow us to deliver
                      the type of content and product offerings in which you are
                      most interested.
                    </li>
                    <li>
                      -To improve our website in order to better serve you.
                    </li>
                    <li>
                      -To allow us to better service you in responding to your
                      customer service requests.
                    </li>
                    <li>
                      -To ask for ratings and reviews of services or products
                    </li>
                    <li>
                      -To follow up with them after correspondence (live chat,
                      email or phone inquiries)
                    </li>
                    <li>
                      -Analytics. To gather metrics to better understand how
                      users access and use our website.
                    </li>
                    <li>
                      -Comply with Law. To comply with legal obligations, as
                      part of our general business operations, and for other
                      business administration purposes.
                    </li>
                    <li>
                      -Prevent Misuse. Where we believe necessary to
                      investigate, prevent or take action regarding illegal
                      activities, suspected fraud, situations involving
                      potential threats to the safety of any person,
                      inappropriate or offensive activities or violations of
                      this Privacy Policy.
                    </li>
                  </ul>
                  <p>
                    Personal Data Collected Automatically. We may also
                    automatically collect information from visitors to our
                    website using cookies.
                  </p>
                </div>
                <h3 className="terms">How do we protect your information?</h3>
                <div className="termsAnswer">
                  <p>
                    We do not use vulnerability scanning and/or scanning to PCI
                    standards. An external PCI compliant payment gateway handles
                    all CC transactions. We do not use Malware Scanning.{" "}
                  </p>
                  <p>
                    Your personal information is contained behind secured
                    networks and is only accessible by a limited number of
                    persons who have special access rights to such systems, and
                    are required to keep the information confidential. In
                    addition, all sensitive/credit information you supply is
                    encrypted via Secure Socket Layer (SSL) technology. We
                    implement a variety of security measures when a user places
                    an order enters, submits, or accesses their information to
                    maintain the safety of your personal information. All
                    transactions are processed through a gateway provider and
                    are not stored or processed on our servers. Warning: The
                    transmission of information over the Internet is not
                    completely secure. Although the Company does its best to
                    protect your personal information, the Company cannot
                    guarantee the security of your personal information
                    transmitted to the Website. Any transmission of personal
                    information is at your own risk. The Company is not
                    responsible for circumvention of any privacy settings or
                    security measures contained on the Website. EU General Data
                    Protection Regulation (GDPR)
                  </p>
                  <p>
                    Beginning May 25, 2018, the processing of personal data of
                    users in the European Union is subject to the EU General
                    Data Protection Regulation - GDPR. If you are a user in the
                    EU you have the right to:
                  </p>
                  <ul>
                    <li>
                      - Request access to information that we have about you;
                    </li>
                    <li>
                      - Receive information related to our use and processing of
                      your information;
                    </li>
                    <li>
                      - Correct or delete any information that we have about
                      you;
                    </li>
                    <li>
                      - Withdraw your consent to our use of your information at
                      any time;
                    </li>
                    <li>
                      - Request that we restrict our use and processing of your
                      information;
                    </li>
                    <li>
                      - Object to our processing of your personal data including
                      for marketing purposes based on profiling and/or automated
                      decision making;
                    </li>
                    <li>
                      - File a complaint relating to our handling of your
                      personal information with a supervisory authority;
                    </li>
                  </ul>
                </div>
                <h3 className="terms">Do we use 'cookies'?</h3>
                <div className="termsAnswer">
                  <p>
                    Yes. Cookies are small files that a site or its service
                    provider transfers to your computer's hard drive through
                    your Web browser (if you allow) that enables the site's or
                    service provider's systems to recognize your browser and
                    capture and remember certain information. For instance, we
                    use cookies to help us remember and process the items in
                    your shopping cart. They are also used to help us understand
                    your preferences based on previous or current site activity,
                    which enables us to provide you with improved services. We
                    also use cookies to help us compile aggregate data about
                    site traffic and site interaction so that we can offer
                    better site experiences and tools in the future.
                  </p>
                </div>

                <h3 className="terms">We use cookies to:</h3>
                <div className="termsAnswer">
                  <ul>
                    <li>
                      - Understand and save user's preferences for future
                      visits.
                    </li>
                    <li>
                      - Compile aggregate data about site traffic and site
                      interactions in order to offer better site experiences and
                      tools in the future. We may also use trusted third-party
                      services that track this information on our behalf.
                    </li>
                  </ul>
                  <p>
                    You can choose to have your computer warn you each time a
                    cookie is being sent, or you can choose to turn off all
                    cookies. You do this through your browser settings. Since
                    browser is a little different, look at your browser's Help
                    Menu to learn the correct way to modify your cookies. If you
                    turn cookies off, Some of the features that make your site
                    experience more efficient may not function properly.It won't
                    affect the user's experience that make your site experience
                    more efficient and may not function properly.
                  </p>
                </div>

                <h3 className="terms">Third-party links</h3>
                <div className="termsAnswer">
                  <p>
                    Occasionally, at our discretion, we may include or offer
                    third-party products or services on our website. These
                    third-party sites have separate and independent privacy
                    policies. We therefore have no responsibility or liability
                    for the content and activities of these linked sites.
                    Nonetheless, we seek to protect the integrity of our site
                    and welcome any feedback about these sites.
                  </p>
                </div>
                <h3 className="terms">Google</h3>
                <div className="termsAnswer">
                  <p>
                    Google's advertising requirements can be summed up by
                    Google's Advertising Principles. They are put in place to
                    provide a positive experience for users.
                    https://support.google.com/adwordspolicy/answer/1316548?hl=en
                    We use Google AdSense Advertising on our website. Google, as
                    a third-party vendor, uses cookies to serve ads on our site.
                    Google's use of the DART cookie enables it to serve ads to
                    our users based on previous visits to our site and other
                    sites on the Internet. Users may opt-out of the use of the
                    DART cookie by visiting the Google Ad and Content Network
                    privacy policy. You can learn more about Google Analytics @
                    https://support.google.com/analytics/answer/6004245 or opt
                    out @ https://tools.google.com/dlpage/gaoptout if you wish.
                    We have implementWe have implemented the following:
                  </p>
                  <ul>
                    <li>- Google Display Network Impression Reporting</li>
                    <li>- Demographics and Interests Reporting</li>
                  </ul>
                  <p>
                    We, along with third-party vendors such as Google use
                    first-party cookies (such as the Google Analytics cookies)
                    and third-party cookies (such as the DoubleClick cookie) or
                    other third-party identifiers together to compile data
                    regarding user interactions with ad impressions and other ad
                    service functions as they relate to our website.
                  </p>
                </div>
                <h3 className="terms">Opting out: </h3>
                <div className="termsAnswer">
                  <p>
                    Users can set preferences for how Google advertises to you
                    using the Google Ad Settings page. Alternatively, you can
                    opt out by visiting the Network Advertising Initiative Opt
                    Out page or by using the Google Analytics Opt Out Browser
                    add on.
                  </p>
                </div>

                <h3 className="terms">
                  COPPA (Children Online Privacy Protection Act)
                </h3>
                <h3 className="terms">
                  Persons under the age of 18 are forbidden from entering our
                  website and/or viewing our media.
                </h3>
                <div className="termsAnswer">
                  <p>
                    When it comes to the collection of personal information from
                    children under the age of 18 years old, the Children's
                    Online Privacy Protection Act (COPPA) puts parents in
                    control. The Federal Trade Commission, United States'
                    consumer protection agency, enforces the COPPA Rule, which
                    spells out what operators of websites and online services
                    must do to protect children's privacy and safety online. We
                    do not specifically market to children under the age of 18
                    years old.
                  </p>
                </div>

                <h3 className="terms">CAN SPAM Act</h3>
                <div className="termsAnswer">
                  <p>
                    The CAN-SPAM Act is a law that sets the rules for commercial
                    email, establishes requirements for commercial messages,
                    gives recipients the right to have emails stopped from being
                    sent to them, and spells out tough penalties for violations.
                  </p>
                  <ul>
                    We collect your email address in order to:
                    <li>
                      - Send information, respond to inquiries, and/or other
                      requests or questions
                    </li>
                    <li>
                      - Process orders and to send information and updates
                      pertaining to orders.
                    </li>
                    <li>
                      - Send you additional information related to your product
                      and/or service
                    </li>
                    <li>
                      - Market to our mailing list or continue to send emails to
                      our clients after the original transaction has occurred.
                    </li>
                    To be in accordance with CANSPAM, we agree to the following:
                    <li>
                      - Not use false or misleading subjects or email addresses.
                    </li>
                    <li>
                      - Identify the message as an advertisement in some
                      reasonable way.
                    </li>
                    <li>
                      - Include the physical address of our business or site
                      headquarters.
                    </li>
                    <li>
                      - Monitor third-party email marketing services for
                      compliance, if one is used.
                    </li>
                    <li>- Honor opt-out/unsubscribe requests quickly.</li>
                    <li>
                      - Allow users to unsubscribe by using the link at the
                      bottom of each email.
                    </li>
                  </ul>
                  <p>
                    If at any time you would like to unsubscribe from receiving
                    future emails, follow the instructions at the bottom of each
                    email and we will promptly remove you from our newsletters.
                  </p>
                </div>
                <h3 className="terms">
                  California Online Privacy Protection Act
                </h3>
                <div className="termsAnswer">
                  <p>
                    CalOPPA is the first state law in the nation to require
                    commercial websites and online services to post a privacy
                    policy. The laws reach stretches well beyond California to
                    require any person or company in the United States (and
                    conceivably the world) that operates websites collecting
                    Personally Identifiable Information from California
                    consumers to post a conspicuous privacy policy on its
                    website stating exactly the information being collected and
                    those individuals or companies with whom it is being shared.
                    see more at:
                    http://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf
                  </p>
                </div>
                <h3 className="terms">Contacting Us</h3>
                <div className="termsAnswer">
                  <p>
                    If there are any questions regarding this privacy policy,
                    you may contact us using the information below.
                  </p>
                  <p>
                    Contact Link: https://addfriends.com/contact/
                    <br />
                    Mobile AF, Inc.
                    <br />
                    318 N. Carson St. #208
                    <br />
                    Carson City, NV 89701, USA
                    <br />
                  </p>
                  <p>
                    <strong>Last Edited On 06/10/2019</strong>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Privacy
